import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useSession, SessionProvider } from "next-auth/react";
import { NextComponentType } from "next";
import Loader from "../components/Loader";
import { Toaster } from "react-hot-toast";

type CustomAppProps = AppProps & {
  Component: NextComponentType & { auth?: boolean }; // add auth type
};

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) {
  return (
    <SessionProvider session={session}>
      <Toaster position="top-center" reverseOrder={false} />

      {Component.auth ? (
        <Auth>
          <Component {...pageProps} />
        </Auth>
      ) : (
        <>
          <Component {...pageProps} />
        </>
      )}
    </SessionProvider>
  );
}

export default MyApp;

const Auth = ({ children }: any) => {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { data: session, status } = useSession({ required: true });

  if (status === "loading") {
    return <Loader text={"Checking authentication..."} />;
  }

  if (status === "authenticated") {
    return children;
  } else {
    return <div>You are not authorized to view this page</div>;
  }
};
